import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
// import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";

import App from './App.vue'

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: '/',
            name: 'Home',
            component: () => import(/* webpackChunkName: "news" */ './views/Home.vue'),
            meta: {
                title: 'gabinet psychologiczny Łódź',
                metaTags: [{
                    name: 'description',
                    content: 'The home page of our example app.'
                },
                {
                    property: 'og:description',
                    content: 'The home page of our example app.'
                }
                ]
            },
        },
        {
            path: '/aktualnosci',
            name: 'News',
            component: () => import(/* webpackChunkName: "news" */ './views/News.vue'),
        },
        {
            path: '/o-nas',
            name: 'About',
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        },
        {
            path: '/psychoterapia-lodz',
            name: 'Contact',
            component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
        },
        {
            path: '/specjalisci',
            name: 'Experts',
            component: () => import(/* webpackChunkName: "experts" */ './views/Experts.vue'),
        },
        {
            path: '/cena-wizyty',
            name: 'Pricelist',
            component: () => import(/* webpackChunkName: "pricelist" */ './views/Pricelist.vue'),
        },
        {
            path: '/wolny-termin',
            name: 'Reservation',
            component: () => import(/* webpackChunkName: "reservation" */ './views/Reservation.vue'),
        },
        {
            path: '/callendar',
            name: 'Callendar',
            component: () => import(/* webpackChunkName: "callendar" */ './views/Callendar.vue'),
        },
        {
            path: '/crisisintervention',
            name: 'CrisisIntervention',
            component: () => import(/* webpackChunkName: "crisisintervention" */ './views/CrisisIntervention.vue'),
        },
        {
            path: '/firstmeeting',
            name: 'FirstMeeting',
            component: () => import(/* webpackChunkName: "firstmeeting" */ './views/FirstMeeting.vue'),
        },
        {
            path: '/psychoterapia',
            name: 'ForReading',
            component: () => import(/* webpackChunkName: "forreading" */ './views/ForReading.vue'),
        },
        {
            path: '/individualpsychotherapy',
            name: 'IndividualPsychotherapy',
            component: () => import(/* webpackChunkName: "individualpsychotherapy" */ './views/IndividualPsychotherapy.vue'),
        },
        {
            path: '/logowanie',
            name: 'Logon',
            component: () => import(/* webpackChunkName: "logon" */ './views/Logon.vue'),
        },
        {
            path: '/mediation',
            name: 'Mediation',
            component: () => import(/* webpackChunkName: "mediation" */ './views/Mediation.vue'),
        },
        {
            path: '/psychotherapycoupandfam',
            name: 'PsychotherapyCoupAndFam',
            component: () => import(/* webpackChunkName: "psychotherapycoupandfam" */ './views/PsychotherapyCoupAndFam.vue'),
        },
        {
            path: '/psychoterapia-online',
            name: 'PsychotherapyOnline',
            component: () => import(/* webpackChunkName: "psychotherapyonline" */ './views/PsychotherapyOnline.vue'),
        },
        {
            path: '/supportgroup',
            name: 'SupportGroup',
            component: () => import(/* webpackChunkName: "supportgroup" */ './views/SupportGroup.vue'),
        },
        {
            path: '/workshops',
            name: 'Workshops',
            component: () => import(/* webpackChunkName: "workshops" */ './views/Workshops.vue'),
        },
        {
            path: '/psychotherapy',
            name: 'Psychotherapy',
            component: () => import(/* webpackChunkName: "psychotherapy" */ './views/Psychotherapy.vue'),
        },
        {
            path: '/forwhom',
            name: 'ForWhom',
            component: () => import(/* webpackChunkName: "forwhom" */ './views/ForWhom.vue'),
        },
        {
            path: '/offer',
            name: 'Offer',
            component: () => import(/* webpackChunkName: "offer" */ './views/Offer.vue'),
        },
        {
            path: '/regulaminy',
            name: 'Regulations',
            component: () => import(/* webpackChunkName: "regulations" */ './views/Regulations.vue'),
        },
    ]
})

// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
// 	id: 'G-1SELF3KF9N'
// });

createApp(App)
// .use(VueAnalytics, {
// 	id: 'G-1SELF3KF9N'
// })
.use(router)
.use(VueGtag, {
    config: { 
      id: "G-1SELF3KF9N",
    //   params: {
    //     anonymize_ip: true
    //   }
    }
  })
.mount('#app')
