<template>
    <div class="headerpage" style="z-index: 1">
        <link href="css/global.css" rel="stylesheet">
        <div class="headers">
            <div id="header" class="top-wrapper">
                <div class="container bg-white" id="HeaderContainer">
                    <div class="row">
                        <router-link to="/">
                            <h1 class="p-1 text-dark float-left" id="psychosoma">Psycho-Soma</h1>
                        </router-link>

                        <router-link to="/wolny-termin" class="kontakty p-3">
                            <img class="smallicon" src="../assets/mobileIcons/greenIcons/calendar-64(1).png">
                            <span class="text-dark">Zarezerwuj wizytę</span>
                        </router-link>
                        <a class="kontakty p-3" href="tel:516767470">
                            <img class="smallicon" src="../assets/mobileIcons/greenIcons/phone-47-64.png">
                            <span class="tel text-dark">+48 51 67 67 47 0</span><br />
                        </a>
                        <a class="kontakty p-3 float-right"
                            href="mailto:kontakt@psycho-soma.pl?Subject=Rezerwacja%20wizyty">
                            <img class="smallicon" src="../assets/mobileIcons/greenIcons/email-64(1).png">
                            <span class="text-dark">kontakt@psycho-soma.pl</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="header2">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                    <a class="navbar-brand" href="#">MENU</a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item active">
                                <router-link to="/o-nas" class="nav-link">O NAS<span
                                        class="sr-only">(current)</span></router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/specjalisci" class="nav-link">ZESPÓŁ</router-link>
                            </li>
                            <!--<li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OFERTA</a>
                                <div class="dropdown-menu" aria-labelledby="dropdown01">
                                    <router-link to="/firstmeeting" class="dropdown-item">PIERWSZE SPOTKANIE</router-link>
                                    <router-link to="/individualpsychotherapy" class="dropdown-item">PSYCHOTERAPIA INDYWIDUALNA</router-link>
                                    <router-link to="/psychotherapyonline" class="dropdown-item">PSYCHOTERAPIA INDYWIDUALNA ONLINE</router-link>
                                    <router-link to="/psychotherapycoupandfam" class="dropdown-item">PSYCHOTERAPIA PAR I RODZIN</router-link>
                                    <router-link to="/crisisintervention" class="dropdown-item">INTERWENCJA KRYZYSOWA</router-link>
                                    <router-link to="/mediation" class="dropdown-item">MEDIACJE</router-link>
                                    <router-link to="/workshops" class="dropdown-item">WARSZTATY</router-link>
                                    <router-link to="/supportgroup" class="dropdown-item">GRUPA WSPARCIA</router-link>
                                    <router-link to="/pricelist" class="dropdown-item">CENNIK</router-link>
                                </div>
                            </li>-->
                            <li class="nav-item">
                                <router-link to="/wolny-termin" class="nav-link">ZAREZERWUJ WIZYTĘ</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/psychoterapia-lodz" class="nav-link">KONTAKT</router-link>
                            </li>
                            <!--<li class="nav-item">
                                <router-link to="/contact" class="nav-link">KONTAKT</router-link>
                            </li>-->
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">STREFA PACJENTA</a>
                                <div class="dropdown-menu" aria-labelledby="dropdown01">
                                    <router-link to="/aktualnosci" class="dropdown-item">AKTUALNOŚCI</router-link>
                                    <router-link to="/cena-wizyty" class="dropdown-item">CENNIK</router-link>
                                    <router-link to="/logowanie" class="dropdown-item">LOGOWANIE</router-link>
                                    <router-link to="/psychoterapia" class="dropdown-item">DO POCZYTANIA</router-link>
                                    <router-link to="/regulaminy" class="dropdown-item">REGULAMINY</router-link>
                                </div>
                            </li>
                            <li class="nav-item">
                                <router-link to="/psychoterapia-online" class="nav-link text-success"><b>KONSULTACJE
                                        ONLINE!</b></router-link>
                            </li>
                            <!--<li class="nav-item">
                                <router-link to="/aktualnosci" class="nav-link text-white bg-success">WSPARCIE W PANDEMII</router-link>
                            </li>-->
                        </ul>
                    </div>
                </nav>
                <div class="spacer11 bg-white"></div>
                <marquee class="marqueeHeader bg-white text-dark">
                    <h4><router-link to="/aktualnosci"><a class="marqueLink text-danger">ZAPRASZAMY do Grupy Rozwojowo -
                                Psychoedukacyjnej dla Młodych Dorosłych!!! ... więcej w Strefie Pacjenta * * * WOLNE
                                TERMINY NA PSYCHOTERAPIĘ!!!* * * </a> <a class="marqueLink text-dark"></a></router-link>
                    </h4>
                </marquee>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Headerpage',

    data() {
        return {
            Items: [
            ]
        }
    }
}
</script>
<style>
.navbar-collapse.collapse {
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important
}

.spacer11 {
    padding-top: 0.5%;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.smallicon {
    margin-right: 20px !important;
    margin-left: 20px !important;
    width: 30px;
    /*max-height: 70% !important;*/
}

#HeaderContainer {
    display: table;
    max-width: inherit;
    max-height: inherit
}

.kontakty {
    display: table-cell;
}

#header {
    width: 95%;
    margin: 0 auto;
}

.header2 {
    width: 95%;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .kontakty {
        display: none;
    }

    .spacer11 {
        display: none;
    }

    #psychosoma {
        font-size: 300%;
    }

    #menutext {
        font-size: 200%;
    }

    .header2 {
        /*width: 95%;
            margin: 0 auto;*/
        height: 80%;
    }

    #header2_navbar {
        line-height: 50px;
    }

    .navbar-toggler {
        height: 60px;
        width: 60px;
    }

    .navbar-toggler-icon {
        width: 40px !important;
        height: 40px !important;
    }

    .dropdown-item {
        font-size: 100%;
    }

    .nav-link {
        font-size: 100%;
    }

    .marqueeHeader {
        display: none;
    }
}

@media screen and (min-width: 769px) {
    .navbar-brand {
        display: none;
    }

    .headers {
        background-color: white;
        height: 60px;
        z-index: 1;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        /*height:2em;*/
    }

    .marqueeHeader:hover {
        cursor: pointer;
        text-decoration: underline;
        color: darkgray !important;
    }
}

/*.carousel {
        width: 1280px;
        height: 720px;
    }*/
/*.navbar-nav {
        font-size: 1.5rem;
        right: 20px;
    }*/
</style>