<template>
    <!-- Footer -->
    <footer class="page-footer font-small">
        <div class="spacer3"></div>
        <marquee class="marqueeFooter bg-white text-dark">
            <h2>
                <router-link to="/aktualnosci"><a class="marqueLink text-danger">ZAPRASZAMY do Grupy Rozwojowo-
                        Psychoedukacyjnej dla Młodych Dorosłych!!!... więcej w Strefie Pacjenta. * * * WOLNE TERMINY NA
                        PSYCHOTERAPIĘ!!! * * *
                        <!--* * *-->
                    </a> <a class="marqueLink text-dark"></a></router-link>
            </h2>
        </marquee>
        <!-- Footer Elements -->
        <div class="container bg-dark" id="maxifooter">
            <!-- Grid row-->
            <div class="row">
                <!-- Grid column -->
                <div class="col-2,5 text-white float-left p-3">
                    <h2>
                        Psycho-Soma
                    </h2>
                    <h6>
                        al. Tadeusza Kościuszki 59/61/lok. 114<br>
                        90-514 Łódź<br>
                        tel. 51 67 67 47 0
                    </h6>
                </div>
                <div class="col-2,5  p-5">
                    <router-link to="/wolny-termin">
                        <img class="smallicon" src="../assets/mobileIcons/greenIcons/calendar-64(1).png">
                        <span class="text-white">Zarezerwuj wizytę</span>
                    </router-link>
                </div>
                <div class="col-2,5 p-5" href="tel:516767470">
                    <img class="smallicon" src="../assets/mobileIcons/greenIcons/phone-47-64.png">
                    <span class="tel text-white">51 67 67 47 0</span><br />
                </div>
                <div class="col-2,5 p-5" href="mailto:kontakt@psycho-soma.pl">
                    <img class="smallicon" src="../assets/mobileIcons/greenIcons/email-64(1).png">
                    <span class="text-white">kontakt@psycho-soma.pl</span>
                </div>
                <div class="col p-3">
                </div>
            </div>
            <!-- Grid row-->
        </div>
        <div class="container bg-dark" id="minifooter">

            <div class="spacer12"></div>
            <div class="row" id="mobile">
                <!-- Grid column -->
                <div class="col-4">
                    <router-link to="/wolny-termin">
                        <img class="smallicon2" src="../assets/mobileIcons/greenIcons/128x128/calendar-128.png">
                    </router-link>
                </div>
                <div class="col-4">
                    <a href="tel:516767470">
                        <img class="smallicon2" src="../assets/mobileIcons/greenIcons/128x128/phone-9-128.png">
                    </a>
                </div>
                <div class="col-4">
                    <a href="mailto:kontakt@psycho-soma.pl? subject=Rezerwacja wizyty">
                        <img class="smallicon2" src="../assets/mobileIcons/greenIcons/128x128/email-128.png">
                    </a>
                </div>
            </div>
            <!-- Grid row-->
            <div class="row-2 text-white" id="adressText">
                <!--<div class="spacer12"></div>-->
                <h1 id="FooterAdressPSYCHOSOMA">
                    Psycho-Soma
                </h1>
                <h5 id="FooterAddresText">
                    al. Tadeusza Kościuszki 59/61<br />
                    pokój nr 114<br>
                    90-514 Łódź<br>
                    tel. 51 67 67 47 0
                </h5>
            </div>
            <div class="spacer12"></div>
        </div>
    </footer>
</template>
<style>
.page-footer {
    width: 95%;
    margin: 0 auto;
}

.spacer12 {
    padding-top: 5%;
}

.spacer3 {
    padding-top: 0.5%;
}

@media screen and (max-width: 768px) {
    #maxifooter {
        display: none;
    }

    /*#FooterAdressPSYCHOSOMA{
            font-size:40px;
        }
        #FooterAddresText{
            font-size:20px;
        }*/
    .smallicon2 {
        height: 50%;
    }

    /*.spacer12{
            padding-top:0%;
        }*/
}

@media screen and (min-width: 769px) {
    .marqueeFooter {
        display: none;
    }

    #minifooter {
        display: none;
    }

    #mobile {
        display: none;
    }

    #maxifooter {
        display: table;
        max-width: inherit;
        max-height: inherit
    }
}
</style>
<script>
export default {
    name: 'Footerpage',
    components: {
    }
}
</script>