<template>
  <div id="app">
    <v-header>
      <Headerpage />
      <!-- <Bookero /> -->
    </v-header>
    <div class="spacer15"></div>
    <PopupBox />
    <router-view />
    <v-footer>
      <Footerpage />
    </v-footer>
    <div id="rodoContainer">
      <div class="rodo">W celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies, które będą zamieszczane w
        Państwa urządzeniu (komputerze, laptopie, smartfonie). W każdym momencie mogą Państwo dokonać zmiany ustawień
        Państwa przeglądarki internetowej i wyłączyć opcję zapisu plików cookies. Ze szczegółowymi informacjami
        dotyczącymi cookies na tej stronie można się zapoznać tutaj:
        <a class="text-success" href="\Polityka prywatności Psycho-Soma.pdf" target='_blank'>Polityka
          prywatności</a>

        <button id="accept-cookies-checkbox" name="accept-cookies" @click="hideRodoContainter">Rozumiem</button>
      </div>
    </div>
  </div>
</template>
<script>
import Headerpage from ".//views/Headerpage.vue";
import Footerpage from ".//views/Footerpage.vue";

// let locstrg = true;

export default {
  name: 'App',
  components: {
    Headerpage,
    Footerpage
  },
  data() {
    return {
      locstrg: localStorage.getItem('rodoContainer'),
      isRodoHidden: null
    }
  },
  methods: {
    hideRodoContainter() {
      if (this.isRodoHidden) {
        document.querySelector("#rodoContainer").classList.add("hideRodoContainer");
      }
      this.isRodoHidden = true;
      localStorage.setItem('isRodoHidden', this.isRodoHidden)
    },
    isRodoContainerVisible() {
      this.isRodoHidden = localStorage.getItem('isRodoHidden');
      if (this.isRodoHidden != null) {
        this.hideRodoContainter();
      }
    }
  },
  mounted() {
    this.isRodoContainerVisible();
  }
};
</script>

<style>
.hideRodoContainer {
  visibility: hidden;
}

#accept-cookies-checkbox {
  background-color: #989898;
  padding: 5px 10px;
  margin-top: 10px;
  color: #FFF;
  display: block;
  max-width: 85px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  cursor: pointer;
}

.rodo {
  position: fixed;
  text-align: center;
  padding: 20px;
  line-height: 15px;
  z-index: 99;
  bottom: -1px;
  left: 0;
  width: 100%;
  background-color: black;
  color: white;
  font-size: 13px;
  z-index: 99999;
}

.spacer15 {
  padding-bottom: 12%;
}

@media screen and (max-width: 768px) {
  .spacer15 {
    display: none;
  }
}
</style>

